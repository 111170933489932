import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { GatsbyImage } from "gatsby-plugin-image";
import defFI from '../../content/assets/images/regga-sig-pp.png'

export default class postTypeList extends React.Component {
  render() {
    const posts = this.props.data.posts.edges,
    location = this.props.location,
    postType = this.props.pageContext.postType,
    defImg = this.props.data.defImg.childImageSharp.resize.src

    return (
      <Layout location={location} title={postType}>
        <Seo
          title={`Post type: ${postType}`}
          description={`${postType} is a post type at Regga @reggarantai`}
          image={defFI}
          url={`/${postType}/`}
          meta={[
            {
              name: `keywords`,
              content: [postType,'regga', 'regga rantai', 'reggarantai','@reggarantai','gerakan jemari akhir pekan'].join(",")
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image:width`,
              content: `907`,
            },
            {
              property: `og:image:height`,
              content: `907`,
            },
            {
              property: `og:image:type`,
              content: `image/png`,
            }
          ]}
        />
        <article className="blog-post">
          <div style={{
            backgroundColor: '#000',
            backgroundSize: 'cover',
            backgroundImage: `url(${defImg})`,
            backgroundPosition: 'center center'
          }}>
            <div id="head-bg">
              <br /><br />
              <header style={{paddingBottom:'334px'}}>
                <h4>Post type</h4>
                <h1 className="animate__animated animate__pulse" itemProp="headline">{postType}</h1>
                <div className="line"/>
              </header>
            </div>
          </div>


          <div id="home-body">
            <section id="articles" className="section">
              <div id="latest-article">
                <div className="container cards">
                  <div className="row">
                    {
                      posts.map(({ node }) => {
                        const title = node.frontmatter.title || node.fields.slug
                          return (
                            <div key={node.fields.slug} className="card">
                              <div className="card-inner">
                                <Link to={node.fields.slug}>
                                  <GatsbyImage
                                    alt={node.frontmatter.title}
                                    image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                                    objectFit="cover"
                                    objectPosition="50% 50%" />
                                  <div className="card-body">
                                    <small className="card-date">{
                                      postType === 'coding' ? `${node.frontmatter.tags.join(', ')}` :  `${node.frontmatter.hijriah}`
                                    }</small>
                                    <h3>{title}</h3>
                                    {
                                      postType !== 'kutipan' && (
                                        <p className="card-p"
                                          dangerouslySetInnerHTML={{
                                            __html: node.frontmatter.description || node.excerpt,
                                          }}
                                        />
                                      )
                                    }
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                        })
                    }
                  </div>
                </div>
              </div>

            </section>
          </div>

        </article>
      </Layout>
    );
  }
}

export const blogListQuery = graphql`query blogListQuery($skip: Int!, $limit: Int!, $postType: String!) {
  posts: allMdx(
    filter: {frontmatter: {postType: {eq: $postType}, draft: {eq: false}}}
    sort: {fields: [frontmatter___date], order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          hijriah
          description
          tags
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 460, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  defImg: file(relativePath: {eq: "images/regga-coffee.jpg"}) {
    childImageSharp {
      resize(width: 10) {
        src
      }
    }
  }
}
`
